import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const requirementRoutes: RouteRecordRaw = {
	path: '/requirement/:requirementId',
	name: RouteKeys.DOC_REQUIREMENT,
	components: {
		default: () => import('./RequirementView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		}
	}
};

export const requirementFromRulesRoutes: RouteRecordRaw = {
	path: '/rules/requirement/:requirementId',
	name: RouteKeys.DOC_REQUIREMENT_RULES,
	components: {
		default: () => import('./RequirementView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isPM } = storeToRefs(useRolesStore());
			return computed(() => !isPM.value);
		}
	}
};
