<template>
	<v-app :class="{ 'no-navigation': isCollapsed, dark: isDark }" class="ms-app">
		<v-progress-linear v-if="isLoading" class="http-loader" color="secondary" data-test-id="http-loader" indeterminate />
		<router-view name="appbar" />
		<router-view name="nav" />
		<messages />

		<v-main class="ms-app-main">
			<router-view />
		</v-main>

		<help-menu-fab v-if="showHelpMenuFab" />
		<sk-dialog v-model="displayHelp" :title="helpStore.helpTitle" max-width="500">
			<template #default>
				{{ helpStore.helpMessage }}
			</template>
			<template #footer>
				<sk-primary-btn text="OK" @click="displayHelp = false" />
			</template>
		</sk-dialog>
	</v-app>
</template>

<style lang="scss" scoped>
.ms-app {
	background-color: var(--sk-background-hex);
	transition: background var(--sk-transition);
	overflow-x: hidden;

	.http-loader {
		position: absolute;
		z-index: 9999;
	}

	&-main {
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - var(--sk-app-bar-height));
		max-height: 100vh;
	}
}
</style>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { VApp, VMain, VProgressLinear } from 'vuetify/components';

import { useTheme } from '@silae/composables';
import { HelpMenuFab, Messages } from '~/components';
import { isCollapsed, useLocale } from '~/composables';
import { useHelpStore, useVeeValidateI18n } from '~/plugins';
import { useLoadingService } from '~/services';
import { useAuthenticationStore } from '~/stores';

import {
	clearStateOnSignOut,
	initBackend,
	initErrorInterceptors,
	initLoadingService,
	initSessionLostRedirection,
	initStorageService,
	initThemeSync,
	useHealthCheck
} from './app.init';
import { SkDialog, SkPrimaryBtn } from '@silae/components';

initBackend();
initSessionLostRedirection();
initErrorInterceptors();
initLoadingService();
initStorageService();
useHealthCheck();
initThemeSync();

clearStateOnSignOut();

const { isDark } = useTheme();

useLocale();
useVeeValidateI18n();

const { isAuthenticated } = storeToRefs(useAuthenticationStore());
const showHelpMenuFab = computed(() => isAuthenticated.value && isCollapsed.value);
const { isLoading } = storeToRefs(useLoadingService());
const helpStore = useHelpStore();

const displayHelp = computed<boolean>({
	get() {
		return helpStore.displayHelp;
	},
	set() {
		helpStore.close();
	}
});

</script>
