// Define the type of the $help method
import { App } from 'vue';
import { useHelpStore } from '~/plugins/help/help-store';

declare module 'vue' {
	interface ComponentCustomProperties {
		$help: (message: string) => void;
	}
}

export const HelpPlugin = {
	install: (app: App) => {
		const helpStore = useHelpStore();
		// Define the $help method
		app.config.globalProperties.$help = (message: string) => {
			helpStore.help(message);
		};
	}
};
