import { Observable } from 'rxjs';
import { useBackendHttpService } from '../http';
import { UserDTO } from '../users';
import { AuthenticateRequestDTO, TokenHintDTO } from './authentication.dto';

export const AUTH_API = '/v1/authenticate';

export function fetchTokenHint$(request: AuthenticateRequestDTO): Observable<TokenHintDTO> {
	const { http } = useBackendHttpService();
	return http.post(`${AUTH_API}/b2c/id-token-hint`, request);
}

export function signInToBackend$(b2cToken: string): Observable<UserDTO> {
	const { http } = useBackendHttpService();
	// TODO remove ?type=v2 when backend has completely replaced current auth with stable v2 version
	return http.get(`${AUTH_API}/bearer`, { headers: { Authorization: `Bearer ${b2cToken}` } });
}

export function signOutFromBackend$(): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${AUTH_API}/logout`);
}

export function fetchPrincipal$(): Observable<UserDTO> {
	const { http } = useBackendHttpService();
	return http.get(`${AUTH_API}/user-context`);
}
