import { RouteRecordRaw } from 'vue-router';
import { RouteKeys } from '~/router/routes.domain';
import { defaultLayoutComponents } from '~/pages/layout';

export const HomeRoute: RouteRecordRaw = {
	path: '/home',
	name: RouteKeys.HOME,
	components: {
		default: () => import('./HomeView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true
	}
};
