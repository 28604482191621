import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useHelpStore = defineStore("helpStore", () => {
	const _displayHelp = ref(false);
	const displayHelp = computed(() => _displayHelp.value);
	const _helpMessage = ref<string>("");
	const helpMessage = computed(() => _helpMessage.value);
	const _helpTitle = ref<string>("");
	const helpTitle = computed(() => _helpTitle.value);

	function help(message: string, title: string = "Aide") {
		_helpMessage.value = message;
		_helpTitle.value = title;
		_displayHelp.value = true;
	}

	function close() {
		_displayHelp.value = false;
		_helpMessage.value = "";
		_helpTitle.value = "";
	}

	return {
		helpMessage,
		helpTitle,
		displayHelp,
		help,
		close,
	};
});
