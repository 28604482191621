import { createApp } from 'vue';

import '~/plugins/polyfills';

import App from './App.vue';
import { HelpPlugin, i18n, setupFontAwesome, setupLogger, setupNotifications, setupVeeValidate, vuetify } from './plugins';
import { router } from './router';
import { setupErrorHandler } from './services/errors';
import { pinia } from './stores';
import './style';

setupLogger();

const app = createApp(App);

// setup Sentry as early as possible
setupErrorHandler(app);

app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(HelpPlugin);

setupFontAwesome(app);
setupNotifications(app);
setupVeeValidate();

app.mount('#app');
