import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { ListEntry } from '@silae/types';
import { DAY_MILLIS, dateBetween, toDate } from '~/utils';

import { Contract, isCDD } from './model';
import { Employee } from './model/employee';


dayjs.extend(isBetween);

const cpQTy = [5, 1, 1, 5, 1, 1, 15, 15, 1, 1, 5, 5];
const rttQTy = [1, 1, 1, 1, 23, 1, 1, 3, 1, 1, 1, 1];
const maladieQTy = [3, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3];

const demandeur234 = [
	'Titin',
	'Milou',
	'Dupont',
	'Dupond',
	'Hadock',
	'Tournsesol',
	'Rastapopoulos',
	'Séraphin',
	'Castafiore',
	'Alcazar',
	'Bergamotte',
	'Rascar Capac',
	'Tchang'
];

const demandeur5 = [
	'Titin',
	'Milou',
	'Dupont',
	'Dupond',
	'Hadock',
	'Tournsesol',
	'Rastapopoulos',
	'Seraphin',
	'Castafiore',
	'Alcazar',
	'Bergamotte',
	'RascarCapac',
	'Tchang',
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined
];

const valideur3 = [
	'Obelix',
	'Asterix',
	'Panoramix',
	'Abraracourcix',
	'Agecanonix',
	'Cetautomatix',
	'Bonemine',
	'Assurancetourix',
	'Idefix',
	'Ordralfabetix',
	'Astronomix'
];

const valideur45 = [
	'Obelix',
	'Asterix',
	'Panoramix',
	'Abraracourcix',
	'Agecanonix',
	'Cetautomatix',
	'Bonemine',
	'Assurancetourix',
	'Idefix',
	'Ordralfabetix',
	'Astronomix',
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined
];

export const absenceGenerationTypes = [
	{
		value: 0,
		label: "Pas d'absences"
	},
	{
		value: 1,
		label: 'Tout validé en Paie'
	},
	{
		value: 2,
		label: 'Tout en attente de validation'
	},
	{
		value: 3,
		label: 'Tout en validé par le manager'
	},
	{
		value: 4,
		label: 'Aléatoire en attente ou validé par le manager'
	},
	{
		value: 5,
		label: 'Aléatoire'
	}
];

export interface AbsenceGenerationType {
	index: number;
	label: string;
}

export interface AbsenceType {
	code: string;
	name: string;
	percent_act: number;
}

export class DayCallendar {
	date: Date;
	selected: boolean;

	constructor(date: Date) {
		this.date = date;
		this.selected = false;
	}

	isOff() {
		return this.date.getDay() == 0 || this.date.getDay() == 6;
	}
}

export class Absence {
	id: number;
	companyId: number;
	establishmentId: number;
	employeeId: number;
	type: string;
	pourcentageActivite: number;
	startDate: Date;
	endDate: Date;
	demandeDate: Date | undefined;
	demandeIdentifiant: string | undefined;
	validationDate: Date | undefined;
	demandeValidateur: string | undefined;
	indexPos = 0;

	constructor(employee: Employee, starDate: Date, endDate: Date, absType: AbsenceType) {
		this.id = -1; //TODO generate by Back
		this.employeeId = employee.id;
		this.companyId = employee.companyId;
		this.establishmentId = employee.establishmentId;
		this.type = absType.code.toString();
		this.pourcentageActivite = absType.percent_act;
		this.startDate = starDate;
		this.startDate.setHours(1, 1, 1, 1);
		this.endDate = endDate;
		this.endDate.setHours(22, 1, 1, 1);
	}
}

export function isOn(thisLeave: Absence, absIn: Absence) {
	return (
		dayjs(absIn.startDate).isBetween(thisLeave.startDate, thisLeave.endDate) ||
		dayjs(absIn.endDate).isBetween(thisLeave.startDate, thisLeave.endDate) ||
		dayjs(thisLeave.startDate).isBetween(absIn.startDate, absIn.endDate) ||
		dayjs(thisLeave.endDate).isBetween(absIn.startDate, absIn.endDate)
	);
}

export function getStatut(thisLeave: Absence): number {
	if (thisLeave.demandeDate === undefined) {
		return 0;
	} else {
		if (thisLeave.validationDate === undefined) {
			return 1;
		} else {
			return 2;
		}
	}
}

export function updateStatut(thisLeave: Absence, value: number) {
	switch (value) {
		case 0: {
			thisLeave.demandeDate = undefined;
			thisLeave.demandeIdentifiant = undefined;
			thisLeave.validationDate = undefined;
			thisLeave.demandeValidateur = undefined;
			break;
		}
		case 1: {
			thisLeave.demandeDate = new Date();
			thisLeave.demandeIdentifiant = getDemandeurName(2);
			thisLeave.validationDate = undefined;
			thisLeave.demandeValidateur = undefined;
			break;
		}
		case 2: {
			thisLeave.demandeDate = new Date();
			thisLeave.demandeIdentifiant = getDemandeurName(2);
			thisLeave.validationDate = new Date();
			thisLeave.demandeValidateur = getValideurName(3);
			break;
		}
	}
}

export function randomAbsType(): AbsenceType {
	const index = Math.floor(Math.random() * (absTypes.length - 1));
	return absTypes[index];
}

function addRandomDemandeur(generateAbsencesType: number, abs: Absence) {
	const demandeurName = getDemandeurName(generateAbsencesType);
	if (demandeurName !== undefined) {
		abs.demandeDate = new Date();
		abs.demandeIdentifiant = demandeurName;
		const valideurName = getValideurName(generateAbsencesType);
		if (valideurName !== undefined) {
			abs.validationDate = new Date();
			abs.demandeValidateur = valideurName;
		}
	}
}

function getDemandeurName(absGenerationType: number): string | undefined {
	let names: (string | undefined)[] = [];
	switch (absGenerationType) {
		case 2: {
			names = demandeur234;
			break;
		}
		case 3: {
			names = demandeur234;
			break;
		}
		case 4: {
			names = demandeur234;
			break;
		}
		case 5: {
			names = demandeur5;
			break;
		}
	}
	if (names.length <= 0) {
		return undefined;
	}
	const indexName = Math.floor(Math.random() * (names.length - 1));
	return names[indexName];
}

function getValideurName(absGenerationType: number): string | undefined {
	let names: (string | undefined)[] = [];
	switch (absGenerationType) {
		case 3: {
			names = valideur3;
			break;
		}
		case 4: {
			names = valideur45;
			break;
		}
		case 5: {
			names = valideur45;
			break;
		}
	}
	if (names.length <= 0) {
		return undefined;
	}
	const indexName = Math.floor(Math.random() * (names.length - 1));
	return names[indexName];
}

export function randomAbsence(
	employee: Employee,
	contract: Contract,
	absGenerationType: number,
	startDate: Date,
	cpCount = 25,
	rttCount = 5,
	absCount = 30
) {
	const tmpCpQty = [...cpQTy];
	const tmpRttQTy = [...rttQTy];
	const tmpMaladieQTy = [...maladieQTy];
	const absencesOut: Absence[] = [];
	randomAbs(absencesOut, employee, contract, absGenerationType, startDate, cpCount, tmpCpQty, {
		name: '',
		code: '300',
		percent_act: 0
	});
	randomAbs(absencesOut, employee, contract, absGenerationType, startDate, rttCount, tmpRttQTy, {
		name: '',
		code: '310',
		percent_act: 0
	});
	randomAbs(absencesOut, employee, contract, absGenerationType, startDate, absCount, tmpMaladieQTy);
	return absencesOut;
}

export function randomAbs(
	absencesOut: Absence[],
	employee: Employee,
	contract: Contract,
	absGenerationType: number,
	startDate: Date,
	count: number,
	randTab: number[],
	absType = randomAbsType()
) {
	let absCountKeep = count;
	let startDateTmp = new Date(toDate(startDate).getTime());
	startDateTmp = dateBetween(startDateTmp, dayjs(startDateTmp).add(5, 'day').toDate());
	while (absCountKeep > 0 && dayjs(startDateTmp).diff(startDate, 'month') < 12) {
		//FIXME check date de montage
		if (dayjs(startDateTmp).isAfter(toDate(contract.startContractDate))) {
			const qty = randTab[startDateTmp.getMonth()];
			if (qty > 0) {
				const nbr = Math.floor(Math.random() * qty) + 1;
				const abs = new Absence(
					employee,
					new Date(startDateTmp),
					new Date(startDateTmp.getTime() + (nbr - 1) * DAY_MILLIS),
					absType
				);
				randTab[startDateTmp.getMonth()] = randTab[startDateTmp.getMonth()] - nbr;
				if (!absenceAlreadyExist(abs, absencesOut)) {
					//don't create abs if employee as leave
					if (!(isCDD(contract) && dayjs(abs.endDate).isAfter(toDate(contract.endContractDate)))) {
						addRandomDemandeur(absGenerationType, abs);
						absencesOut.push(abs);
						randTab[startDateTmp.getMonth()] = randTab[startDateTmp.getMonth()] - nbr;
						absCountKeep -= nbr;
					}
				}
				startDateTmp = new Date(abs.endDate.getTime() + (nbr + 1) * DAY_MILLIS);
			} else {
				startDateTmp = dayjs(startDateTmp).add(1, 'month').toDate();
			}
		} else {
			startDateTmp = dayjs(startDateTmp).add(1, 'month').toDate();
		}
	}
	return absencesOut;
}

function absenceAlreadyExist(abs: Absence, list: Absence[]) {
	const startAbs = dayjs(abs.startDate);
	const endAbs = dayjs(abs.endDate);
	for (let index = 0; index < list.length; index++) {
		const absTest = list[index];
		const startAbsTest = dayjs(absTest.startDate);
		const endAbsTest = dayjs(absTest.endDate);
		if (startAbs.isBetween(startAbsTest, endAbsTest)) {
			return true;
		}
		if (endAbs.isBetween(startAbsTest, endAbsTest)) {
			return true;
		}
	}
	return false;
}

export const absTypes: AbsenceType[] = [
	{
		code: '100',
		name: 'Maladie non professionnelle',
		percent_act: 0
	},
	{
		code: '101',
		name: 'Congé pathologique pré-natal (14 jours)',
		percent_act: 0
	},
	{
		code: '102',
		name: 'Hospitalisation Maladie non professionnelle',
		percent_act: 0
	},
	{
		code: '103',
		name: 'Maladie non professionnelle (IJSSAT/multi-employeurs)',
		percent_act: 0
	},
	{
		code: '104',
		name: 'Congé pathologique post-natal',
		percent_act: 0
	},
	{
		code: '105',
		name: 'Maladie non professionnelle (ALD)',
		percent_act: 0
	},
	{
		code: '106',
		name: 'Longue maladie (secteur public)',
		percent_act: 0
	},
	{
		code: '107',
		name: 'Maladie longue durée (secteur public)',
		percent_act: 0
	},
	{
		code: '108',
		name: 'Femme enceinte dispensée de travail',
		percent_act: 0
	},
	{
		code: '109',
		name: 'Accident de course',
		percent_act: 0
	},
	{
		code: '110',
		name: 'Maladie professionnelle',
		percent_act: 0
	},
	{
		code: '111',
		name: 'Congé pathologique pré-natal (14 jours) sans maintien',
		percent_act: 0
	},
	{
		code: '120',
		name: 'Accident de travail',
		percent_act: 0
	},
	{
		code: '130',
		name: 'Accident de trajet',
		percent_act: 0
	},
	{
		code: '131',
		name: 'Hospitalisation Accident de trajet',
		percent_act: 0
	},
	{
		code: '132',
		name: 'Hospitalisation AT',
		percent_act: 0
	},
	{
		code: '140',
		name: 'Temps partiel thérapeutique - obsolète',
		percent_act: 50
	},
	{
		code: '141',
		name: 'Temps partiel thérapeutique (AT/MP) - obsolète',
		percent_act: 50
	},
	{
		code: '142',
		name: 'Temps partiel thérapeutique (MNP)',
		percent_act: 50
	},
	{
		code: '143',
		name: 'Temps partiel thérapeutique (AT)',
		percent_act: 50
	},
	{
		code: '144',
		name: 'Temps partiel thérapeutique (ATT)',
		percent_act: 50
	},
	{
		code: '145',
		name: 'Temps partiel thérapeutique (MP)',
		percent_act: 50
	},
	{
		code: '150',
		name: 'Invalidité catégorie 1',
		percent_act: 0
	},
	{
		code: '151',
		name: 'Invalidité catégorie 2',
		percent_act: 0
	},
	{
		code: '152',
		name: 'Invalidité catégorie 3',
		percent_act: 0
	},
	{
		code: '153',
		name: 'Maladie en cours de navigation (MCN)',
		percent_act: 0
	},
	{
		code: '154',
		name: 'Maladie hors navigation (MHN)',
		percent_act: 0
	},
	{
		code: '200',
		name: 'Maternité',
		percent_act: 0
	},
	{
		code: '210',
		name: 'Paternité',
		percent_act: 0
	},
	{
		code: '220',
		name: 'Adoption',
		percent_act: 0
	},
	{
		code: '230',
		name: 'Congé parental',
		percent_act: 0
	},
	{
		code: '240',
		name: 'Congé de présence parentale',
		percent_act: 0
	},
	{
		code: '240',
		name: 'Congé de présence parentale',
		percent_act: 0
	},
	{
		code: '250',
		name: 'Congé de proche aidant',
		percent_act: 0
	},
	{
		code: '251',
		name: 'Congé de solidarité familiale',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '260',
		name: 'Absence événement familial (sans retenue)',
		percent_act: 0
	},
	{
		code: '261',
		name: 'Maladie grave conjoint',
		percent_act: 0
	},
	{
		code: '262',
		name: 'Maladie grave enfant',
		percent_act: 0
	},
	{
		code: '263',
		name: 'Maladie enfant',
		percent_act: 0
	},
	{
		code: '264',
		name: 'Congé conventionnel / maintien total',
		percent_act: 0
	},
	{
		code: '265',
		name: 'Congé conventionnel / maintien partiel',
		percent_act: 50
	},
	{
		code: '270',
		name: 'Congé de deuil',
		percent_act: 0
	},
	{
		code: '300',
		name: 'Congés payés',
		percent_act: 0
	},
	{
		code: '301',
		name: 'Férié chômé',
		percent_act: 0
	},
	{
		code: '310',
		name: 'RTT',
		percent_act: 0
	},
	{
		code: '320',
		name: 'Repos compensateur obligatoire',
		percent_act: 0
	},
	{
		code: '330',
		name: 'Repos compensateur de remplacement',
		percent_act: 0
	},
	{
		code: '335',
		name: 'Repos compensateur (jour)',
		percent_act: 0
	},
	{
		code: '340',
		name: 'Repos compensateur complémentaire',
		percent_act: 0
	},
	{
		code: '341',
		name: 'Heures banque',
		percent_act: 0
	},
	{
		code: '350',
		name: 'Repos forfait jour',
		percent_act: 0
	},
	{
		code: '360',
		name: 'CP supplémentaires',
		percent_act: 0
	},
	{
		code: '361',
		name: 'CP supplémentaires 2',
		percent_act: 0
	},
	{
		code: '362',
		name: 'Congé supplémentaire apprenti',
		percent_act: 0
	},
	{
		code: '400',
		name: 'Congé individuel de formation',
		percent_act: 0
	},
	{
		code: '401',
		name: 'CPF de transition professionnelle',
		percent_act: 0
	},
	{
		code: '410',
		name: 'Congé formation rémunérée',
		percent_act: 0
	},
	{
		code: '412',
		name: 'Congé de restructuration',
		percent_act: 0
	},
	{
		code: '413',
		name: 'Congé pour formation syndicale',
		percent_act: 0
	},
	{
		code: '414',
		name: 'Congé de reconversion',
		percent_act: 0
	},
	{
		code: '415',
		name: 'Congé pour VAE',
		percent_act: 0
	},
	{
		code: '416',
		name: 'Congé pour bilan de compétences',
		percent_act: 0
	},
	{
		code: '417',
		name: 'Congé de reclassement (au-delà du préavis)',
		percent_act: 0
	},
	{
		code: '418',
		name: 'Congé de mobilité (au-delà du préavis)',
		percent_act: 0
	},
	{
		code: '419',
		name: 'Congé de mobilité (ordonnances Macron)',
		percent_act: 0
	},
	{
		code: '420',
		name: 'Absence formation en alternance',
		percent_act: 0
	},
	{
		code: '430',
		name: 'Absence Formation non rémunérée',
		percent_act: 0
	},
	{
		code: '500',
		name: 'Solidarité internationale',
		percent_act: 0
	},
	{
		code: '510',
		name: 'Absence légale autorisée sans retenue',
		percent_act: 0
	},
	{
		code: '520',
		name: 'Autre absence légale avec retenue',
		percent_act: 0
	},
	{
		code: '530',
		name: 'Absence pour Représentation des salariés',
		percent_act: 0
	},
	{
		code: '531',
		name: 'Mobilité volontaire sécurisée',
		percent_act: 0
	},
	{
		code: '532',
		name: 'Congé sabbatique',
		percent_act: 0
	},
	{
		code: '540',
		name: 'Absence entraînement/compétition sportive',
		percent_act: 0
	},
	{
		code: '600',
		name: 'Chômage intempéries',
		percent_act: 0
	},
	{
		code: '608',
		name: 'Activité partielle (réduction du temps)',
		percent_act: 50
	},
	{
		code: '609',
		name: 'Chômage partiel (activité partielle de longue durée)',
		percent_act: 50
	},
	{
		code: '610',
		name: 'Activité partielle (suspension intégrale)',
		percent_act: 50
	},
	{
		code: '611',
		name: 'Chômage partiel/congés payés',
		percent_act: 50
	},
	{
		code: '612',
		name: 'Activité partielle/formation',
		percent_act: 50
	},
	{
		code: '613',
		name: 'Activité partielle LD (réduction du temps)',
		percent_act: 50
	},
	{
		code: '614',
		name: 'Activité partielle LD (suspension temporaire)',
		percent_act: 50
	},
	{
		code: '620',
		name: 'Absence non rémunérée (autorisée)',
		percent_act: 0
	},
	{
		code: '630',
		name: 'Absence non rémunérée (non autorisée)',
		percent_act: 0
	},
	{
		code: '631',
		name: 'Absence rémunéré',
		percent_act: 0
	},
	{
		code: '632',
		name: 'Congé sans solde',
		percent_act: 0
	},
	{
		code: '633',
		name: 'Annulation absence',
		percent_act: 0
	},
	{
		code: '634',
		name: 'Férié chômé non payé',
		percent_act: 0
	},
	{
		code: '635',
		name: 'Cessation concertée de travail (grève)',
		percent_act: 0
	},
	{
		code: '640',
		name: 'Mise à pied disciplinaire',
		percent_act: 0
	},
	{
		code: '641',
		name: 'Mise à pied conservatoire',
		percent_act: 0
	},
	{
		code: '642',
		name: 'Détention provisoire',
		percent_act: 0
	},
	{
		code: '650',
		name: 'Préavis non effectué',
		percent_act: 0
	},
	{
		code: '651',
		name: 'Préavis non effectué payé',
		percent_act: 0
	},
	{
		code: '652',
		name: 'Préavis non effectué payé (congé de reclassement)',
		percent_act: 0
	},
	{
		code: '653',
		name: 'Préavis non effectué payé (congé de mobilité)',
		percent_act: 0
	},
	{
		code: '660',
		name: 'Période non travaillée (CDI intermittent sans lissage)',
		percent_act: 0
	},
	{
		code: '670',
		name: "Préretraite d'entreprise (sans rupture de contrat de travail)",
		percent_act: 0
	},
	{
		code: '680',
		name: "Détachement (établissement d'origine)",
		percent_act: 0
	},
	{
		code: '685',
		name: "Congé pour création ou reprise d'entreprise",
		percent_act: 0
	},
	{
		code: '690',
		name: 'Activité partielle (réduction du temps) - Impossibilité',
		percent_act: 50
	},
	{
		code: '691',
		name: 'Activité partielle (suspension intégrale) - Impossibilité',
		percent_act: 50
	}
];
export const absTypesListItem = absTypes.map((absType, index, arr) => {
	return {
		label: absType.name,
		value: index
	} as ListEntry;
});

function getIndexAbsence(abs: Absence, leaves: Absence[]): number {
	let index = 0;
	leaves.forEach(absEmpl => {
		if (isOn(absEmpl, abs)) {
			index++;
		}
	});

	return index;
}

export function updateAbsencesPosition(employeeId: number, leaves: Absence[]) {
	if (leaves.length > 0) {
		leaves[0].indexPos = 0;
		for (let index = 1; index < leaves.length; index++) {
			const arr = leaves.slice(0, index);
			const abs = leaves[index];
			abs.indexPos = getIndexAbsence(abs, arr);
		}
	}
}
