import { createI18n } from 'vue-i18n';

import en from '~/i18n/en.json';
import fr from '~/i18n/fr.json';

const i18n = createI18n({
	fallbackLocale: 'fr', // set fallback locale
	globalInjection: true,
	legacy: true, // use composition API
	locale: 'fr', // set locale
	messages: {
		en,
		fr
	}
});

export { i18n };
