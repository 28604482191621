import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

export const SignInRoute: RouteRecordRaw = {
	name: RouteKeys.SIGN_IN,
	path: '/sign-in',
	//props: (route) => ({ emailAsBase64: route.params.emailAsBase64, redirect: route.query.redirect }),
	component: () => import('./sign-in.vue'),
	meta: {
		public: true
	}
};

export const ConnectionRoute: RouteRecordRaw = {
	name: RouteKeys.CONNECTION,
	path: '/connection',
	component: () => import('./connection.vue'),
	meta: {
		public: true
	}
};
