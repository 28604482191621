import { POSITIVE_MOULA_REGEX } from '~/utils/regex.utils';
import { randomString } from '~/utils/string.utils';

export function parseMoula(val: string): number {
	if (!val || val.length === 0) {
		return null;
	}

	if (!POSITIVE_MOULA_REGEX.test(val)) {
		return NaN;
	}

	const numericalValue = val.replaceAll(',', '.');
	return parseFloat(numericalValue);
}

export function randomNumber(length: number, characters = '123456789'): string {
	return randomString(length, characters);
}

export function sizedNumber(num: number | undefined | string, size: number): string {
	if (num === undefined) {
		return 'UN_ERROR';
	} else {
		let numString: string = num as string;
		if (!Number.isNaN(num)) {
			numString = Number(num).toFixed(0);
		}
		if (numString.length < size) {
			const addSize = size - numString.length;
			let addText = '';
			for (let index = 0; index < addSize; index++) {
				addText += '0';
			}
			return addText + numString;
		} else {
			return numString;
		}
	}
}
