export function prettyEmployeeName(firstName: string, lastName: string): string {
	// clean lastName
	if (lastName != null && lastName.trim() !== "") {
		lastName = `${lastName.toUpperCase().trim()}`;
	} else {
		lastName = "";
	}

	// clean firstName
	if (firstName != null && firstName.trim() !== "") {
		firstName = `${firstName
			.toLowerCase()
			.replace(/(^|[\s-])[A-Za-zÀ-ÖØ-öø-ÿ]/g, (c) => c.toUpperCase())
			.trim()}`;
	} else {
		firstName = "";
	}

	return `${firstName} ${lastName}`.trim();
}
