import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed, ComputedRef } from 'vue';

import { QA2Right } from '~/api';
import { useAuthenticationStore } from '~/stores/authentication.store';


export type RolesStore = {
	isQA: ComputedRef<boolean>;
	isVisitor: ComputedRef<boolean>;
	isUser: ComputedRef<boolean>;
	isBeginner: ComputedRef<boolean>;
	isAdmin: ComputedRef<boolean>;
	isSuperAdmin: ComputedRef<boolean>;
	isSupport: ComputedRef<boolean>;
	isPM: ComputedRef<boolean>;
};

export const useRolesStore = defineStore<'roles-store', RolesStore>('roles-store', () => {
	const { userRights } = storeToRefs(useAuthenticationStore());

	const isSuperAdmin = computed(() => userRights.value.includes(QA2Right.SUPER_ADMIN));
	const isAdmin = computed(() => userRights.value.includes(QA2Right.ADMIN));
	const isVisitor = computed(() => userRights.value.includes(QA2Right.VISITOR));
	const isUser = computed(() => userRights.value.includes(QA2Right.USER));
	const isBeginner = computed(() => userRights.value.includes(QA2Right.BEGINNER));
	const isPM = computed(() => userRights.value.includes(QA2Right.PM));
	const isSupport = computed(() => userRights.value.includes(QA2Right.SUPPORT));
	const isQA = computed(() => userRights.value.includes(QA2Right.QA));

	return {
		isQA,
		isPM,
		isVisitor,
		isUser,
		isBeginner,
		isSupport,
		isAdmin,
		isSuperAdmin
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useRolesStore, import.meta.hot));
