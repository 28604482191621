import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';
import { storeToRefs } from 'pinia';
import { useRolesStore } from '~/stores';
import { computed } from 'vue';


export const QaAutoRoute: RouteRecordRaw = {
	path: '/qa-auto',
	name: RouteKeys.QA_AUTO_HOME,
	components: {
		default: () => import('./QaAutoView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isNavEntry: true,
		isForbidden: () => {
			const { isSuperAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isSuperAdmin.value);
		},
		label: 'qaauto.navigation',
		icon: 'robot',
		requiresAuth: true,
		order: 3
	}
};
