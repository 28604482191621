export interface CCN {
	code: string;
	name: string;
}

export const CCNs: CCN[] = [
	{
		code: 'A044',
		name: 'Automobile'
	},
	{
		code: 'B006',
		name: 'Bâtiment (Cadres)'
	},
	{
		code: 'B008',
		name: 'Bâtiment [ETAM]'
	},
	{
		code: 'B065',
		name: "Bureaux d'étude techniques"
	},
	{
		code: 'C035',
		name: 'Chimie (industries)'
	},
	{
		code: 'E026',
		name: 'Expert-comptables et commissaires aux comptes'
	},
	{
		code: 'H019',
		name: 'Hôtels, cafés, restaurants'
	},
	{
		code: 'I005',
		name: 'Insertion (ateliers et chantiers)'
	},
	{
		code: 'M017',
		name: 'Métallurgie (accords nationnaux: ouvriers, ETAM, ingénieurs et cadres)'
	},
	{
		code: 'M018',
		name: 'Métallurgie (ingénieurs et cadres)'
	},
	{
		code: 'M046',
		name: 'Métallurgie (Ille-et-Vilaine - Morbihan)'
	},
	{
		code: 'P055',
		name: 'Publicité'
	},
	{
		code: 'T029',
		name: 'Travail temporaire (salariés parmanents)'
	}
];

export const CcnIds = CCNs.map((ccn, index) => {
	return {
		value: index,
		label: ccn.name
	};
});

export function randomCCN(): CCN {
	const index = Math.floor(Math.random() * (CCNs.length - 1));
	return CCNs[index];
}
