<template>
	<v-list density="compact">
		<v-list-item :title="$t('help.app_infos.version', { version })" density="compact" />
		<v-list-item :title="$t('help.app_infos.update_date', { date })" density="compact" />
	</v-list>
</template>

<style lang="scss" scoped>
.documentation-version {
	line-height: 150%;
}
</style>

<script lang="ts" setup>
import { VList, VListItem } from 'vuetify/components';

import { useAppVersion } from '~/composables';

const { date, version } = useAppVersion();
</script>
