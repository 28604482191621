export function randomString(length: number, characters: string = 'abcdefghijklmnopqrstuvwxyz0') {
	let result = '';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}

export function cleanString(text: string | undefined, maxsize = -1): string {
	if (text === undefined) {
		return 'UN_ERROR';
	}
	if (maxsize > 0) {
		return (
			text
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.substring(0, maxsize)
				//xml encode
				.replace(/&/g, '&amp;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/"/g, '&quot;')
				.replace(/'/g, '&apos;')
		);
	} else {
		return (
			text
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				//xml encode
				.replace(/&/g, '&amp;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/"/g, '&quot;')
				.replace(/'/g, '&apos;')
		);
	}
}
