// @ts-expect-error: FontAwesome custom packaging types are not correctly generated
import { byPrefixAndName } from '@awesome.me/kit-964b811214/icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faAlienMonster,
	faArrowDownToLine,
	faArrowLeftLongToLine,
	faArrowRightArrowLeft,
	faArrowRightLongToLine,
	faArrowUpRightFromSquare,
	faBackwardFast,
	faBackwardStep,
	faBomb,
	faBoxesStacked,
	faBuilding,
	faCalendarDay,
	faCheck,
	faChevronDown,
	faChevronUp,
	faCircle,
	faCircleCheck,
	faCircleEllipsis,
	faCircleEuro,
	faCircleInfo,
	faCirclePlus,
	faClock,
	faCompassDrafting,
	faCopy,
	faEllipsisV as faEllipsisVertical,
	faEye,
	faFile,
	faFileDoc,
	faFileExcel,
	faFileImage,
	faFileWord,
	faFloppyDisk,
	faFolder,
	faFolderOpen,
	faForwardFast,
	faForwardStep,
	faGears,
	faGhost,
	faHorizontalRule,
	faHouseChimney,
	faListTree,
	faMagnifyingGlass,
	faMinus,
	faMoon,
	faPen,
	faPeriod,
	faPerson,
	faPlus,
	faPowerOff,
	faRotate,
	faRotateLeft,
	faServer,
	faSort,
	faSpinner,
	faSpinnerThird,
	faSquare,
	faStar as faRatingEmpty,
	faStarHalf,
	faSunBright,
	faToggleOff,
	faToggleOn,
	faTrashCan,
	faTriangleExclamation,
	faUmbrellaBeach,
	faUser,
	faUserGraduate,
	faUsers,
	faUsersViewfinder,
	faWandMagicSparkles,
	faXmark
} from '@fortawesome/pro-light-svg-icons';
import {
	faBuildingUser,
	faChartColumn,
	faCheckDouble,
	faCircleDot as faRadioOn,
	faCircleExclamation,
	faPencil,
	faRobot,
	faSquareCheck,
	faSquareMinus,
	faStar,
	faUserPlus,
	faVialCircleCheck
} from '@fortawesome/pro-solid-svg-icons';

export const ICON_LIBRARY: { [keys: string]: IconDefinition } = {
	faGears,
	faUsersViewfinder,
	faAddCircle: byPrefixAndName.fak['add-circle'],
	faAlienMonster,
	faArrowBendLeftDown: byPrefixAndName.fak['arrow-bend-left-down'],
	faArrowDown: byPrefixAndName.fak['arrow-down'],
	faArrowDownToLine,
	faArrowLeft: byPrefixAndName.fak['arrow-left'],
	faArrowLeftLongToLine,
	faArrowRight: byPrefixAndName.fak['arrow-right'],
	faArrowRightLongToLine,
	faArrowRightArrowLeft,
	faArrowUp: byPrefixAndName.fak['arrow-up'],
	faArrowUpRightFromSquare,
	faAssignGroup: byPrefixAndName.fak['asign-group'],
	faAutoAddedFrame: byPrefixAndName.fak['auto-added-frame'],
	faAvatar: byPrefixAndName.fak['avatar'],
	faBackArrow: byPrefixAndName.fak['back-arrow'],
	faBomb,
	faBackwardFast,
	faBackwardStep,
	faBoxesStacked,
	faBuildingUser,
	faBuilding,
	faBagage: byPrefixAndName.fak['bagage'],
	faBilling: byPrefixAndName.fak['billling'],
	faBin: byPrefixAndName.fak['bin'],
	faCancel: byPrefixAndName.fak['cancel'],
	faCalendar: byPrefixAndName.fak['calendar'],
	faCalendarAdd: byPrefixAndName.fak['calendar-add'],
	faCalendarDay,
	faCardClose: byPrefixAndName.fak['card-close'],
	faCardCryptogram: byPrefixAndName.fak['card-cryptogram'],
	faCardOpen: byPrefixAndName.fak['card-open'],
	faCopy,
	faChartColumn,
	faChat: byPrefixAndName.fak['chat'],
	faCheck,
	faCheckDouble,
	faChecklist: byPrefixAndName.fak['checklist'],
	faChevronDown,
	faChevronLeft: byPrefixAndName.fak['arrow-left'],
	faChevronsLeft: byPrefixAndName.fak['double-arrow-left'],
	faChevronRight: byPrefixAndName.fak['arrow-right'],
	faChevronsRight: byPrefixAndName.fak['double-arrow-right'],
	faChevronUp,
	faCircle,
	faCircleCheck,
	faCircleEllipsis,
	faCircleExclamation,
	faCircleInfo,
	faCircleEuro,
	faCirclePlus,
	faCompassDrafting,
	faClock,
	faClose: byPrefixAndName.fak['close'],
	faCompany: byPrefixAndName.fak['company'],
	faConfiguration: byPrefixAndName.fak['configuration'],
	faContact: byPrefixAndName.fak['contact'],
	faContract: byPrefixAndName.fak['contract'],
	faDashboard: byPrefixAndName.fak['dashboard'],
	faDesktop: byPrefixAndName.fak['desktop'],
	faDocument: byPrefixAndName.fak['document'],
	faDot: byPrefixAndName.fak['dot'],
	faDownArrow: byPrefixAndName.fak['down-arrow'],
	faDownload: byPrefixAndName.fak['download'],
	faDownloadOnlyOne: byPrefixAndName.fak['download-only-one'],
	faDownloadSeveral: byPrefixAndName.fak['download-several'],
	faEllipsisVertical,
	faEye,
	faExpand: byPrefixAndName.fak['expand'],
	faFamily: byPrefixAndName.fak['family'],
	faFavorite: byPrefixAndName.fak['favorite'],
	faFile,
	faFiles: byPrefixAndName.fak['files'],
	faFileDoc,
	faFileExcel,
	faFileImage,
	faFilePdf: byPrefixAndName.fak['pdf'],
	faFileWord,
	faFolder,
	faFolderOpen,
	faForwardFast,
	faForwardStep,
	faFloppyDisk,
	faGeolocation: byPrefixAndName.fak['geolocation'],
	faGhost,
	faGraduation: byPrefixAndName.fak['graduation'],
	faGroupAvatar: byPrefixAndName.fak['group-avatar'],
	faHelp: byPrefixAndName.fak['help'],
	faHealth: byPrefixAndName.fak['health'],
	faHide: byPrefixAndName.fak['hide'],
	faHorizontalRule,
	faHourglass: byPrefixAndName.fak['hourglass'],
	faHouseChimney,
	faHome: byPrefixAndName.fak['home'],
	faIdentity: byPrefixAndName.fak['identity'],
	faImport: byPrefixAndName.fak['import'],
	faInfo: byPrefixAndName.fak['info'],
	faInfoFull: byPrefixAndName.fak['info-full'],
	faKebabMenu: byPrefixAndName.fak['kebab-menu'],
	faLanguage: byPrefixAndName.fak['language'],
	faLegale: byPrefixAndName.fak['legale'],
	faLegend: byPrefixAndName.fak['legend'],
	faListBullets: byPrefixAndName.fak['list-bullets'],
	faListCheck: byPrefixAndName.fak['list-checks'],
	faListTree,
	faLogOut: byPrefixAndName.fak['log-out'],
	faMagnifyingGlass,
	faMeeting: byPrefixAndName.fak['meeting'],
	faMenu: byPrefixAndName.fak['menu'],
	faMinus,
	faMoon,
	faNoGroup: byPrefixAndName.fak['no-group'],
	faPayrollCycle: byPrefixAndName.fak['payroll-cycle'],
	faPadlockClose: byPrefixAndName.fak['padlock-close'],
	faPen,
	faPencil,
	faPeriod,
	faPerson,
	faPlus,
	faPowerOff,
	faRh: byPrefixAndName.fak['rh'],
	faRoleAdmin: byPrefixAndName.fak['role-admin'],
	faRoleEmployee: byPrefixAndName.fak['role-employee'],
	faRoleManager: byPrefixAndName.fak['role-manager'],
	faRobot,
	faRadioOn,
	faRatingEmpty,
	faRefresh: byPrefixAndName.fak['refresh'],
	faRemove: byPrefixAndName.fak['remove'],
	faRemoveFull: byPrefixAndName.fak['remove-full'],
	faRest: byPrefixAndName.fak['rest'],
	faRotate,
	faRotateLeft,
	faSearch: byPrefixAndName.fak['search'],
	faServer,
	faSetting: byPrefixAndName.fak['setting'],
	faShow: byPrefixAndName.fak['show'],
	faSort,
	faSortDown: byPrefixAndName.fak['sort-down'],
	faSortOut: byPrefixAndName.fak['sort-out'],
	faSortUp: byPrefixAndName.fak['sort-up'],
	faSpinnerThird,
	faSpinner,
	faSquare,
	faSquareCheck,
	faSquareMinus,
	faStar,
	faStarHalf,
	faSunBright,
	faToggleOff,
	faToggleOn,
	faTrashCan,
	faTriangleExclamation,
	faTwoAvatar: byPrefixAndName.fak['two-avatar'],
	faUmbrellaBeach,
	faUnemployment: byPrefixAndName.fak['unemployment'],
	faUpArrow: byPrefixAndName.fak['up-arrow'],
	faUser,
	faUserPlus,
	faUserGraduate,
	faUsers,
	faValidate: byPrefixAndName.fak['validate'],
	faValidateFull: byPrefixAndName.fak['validate-full'],
	faVialCircleCheck,
	faWandMagicSparkles,
	faWarning: byPrefixAndName.fak['warning'],
	faWarningFull: byPrefixAndName.fak['warning-full'],
	faXmark
};
