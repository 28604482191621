import { createVuetify } from 'vuetify';
import { fr } from 'vuetify/locale';
import 'vuetify/styles';

import { vuetifyIcons } from './icons';
import { vuetifyTheme } from './theme';
import { Ripple, Tooltip } from 'vuetify/directives';


const vuetify = createVuetify({
	display: {
		mobileBreakpoint: 'sm',
		thresholds: {
			xs: 0,
			sm: 1024,
			md: 1280,
			lg: 1536,
			xl: 1920
		}
	},
	locale: {
		locale: 'fr',
		fallback: 'fr',
		messages: { fr }
	},
	directives: {
		Tooltip,
		Ripple
	},
	icons: vuetifyIcons,
	theme: vuetifyTheme

});

export { vuetify, vuetifyIcons, vuetifyTheme };
