import { defineStore } from 'pinia';
import { computed, ref, WritableComputedRef } from 'vue';

import { Clearable } from '~/stores/store.domain';

export type StorageService = Clearable & {
	read<T>(key: string): T;
	setId(id: string): void;
	write(key: string, val: any): void;
	computedStorage<T>(key: string): WritableComputedRef<T, T>;
};

export const useStorageService = defineStore<'storage-service', StorageService>('storage-service', () => {
	const _id = ref();

	const _prefix = computed(() => _id.value ?? import.meta.env.QA2_APP_NAME);
	const clear = () => (_id.value = null);
	const setId = (id: string) => (_id.value = id);
	const write = (key: string, val: any) => {
		if (val == null || key == null) return;
		localStorage.setItem(`${_prefix.value}:${key}`, JSON.stringify(val));
	};
	const read = (key: string) => {
		if (key == null) return;
		const item = localStorage.getItem(`${_prefix.value}:${key}`);
		return item != null ? JSON.parse(item) : undefined;
	};

	function computedStorage<T>(key: string): WritableComputedRef<T, T> {
		return computed<T>({
			get() {
				return read(key);
			},
			set(newValue: T) {
				write(key, newValue);
			}
		});
	}

	return { clear, read, setId, write, computedStorage };
});
