import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

import { settingsLayoutComponents } from '../layout';
import { SecurityRoute } from './security/security.route';


export const AccountRoute: RouteRecordRaw = {
	name: RouteKeys.ACCOUNT,
	path: '/account',
	components: {
		default: () => import('./account.vue'),
		...settingsLayoutComponents
	},
	redirect: () => SecurityRoute,
	children: [SecurityRoute]
};
