import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { defaultLayoutComponents } from '~/pages/layout';
import { EmployeeRoute } from '~/pages/paiegen/employee/employee-routes';
import { EstablishmentRoute } from '~/pages/paiegen/establishment/establishement-routes';
import { TeamRoute } from '~/pages/paiegen/team/team-routes';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const PayGenMultiRoutes: RouteRecordRaw = {
	path: 'multi',
	name: RouteKeys.PAY_GEN_MULTI,
	component: () => import('./MultiGenView.vue'),
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isQA } = storeToRefs(useRolesStore());
			return computed(() => !isQA.value);
		},
		label: 'paygen.multi.navigation',
		icon: 'list-tree',
		isSubEntry: true,
		order: 3
	}
};

export const PayGenViewRoute: RouteRecordRaw = {
	path: '',
	name: RouteKeys.PAY_GEN_SINGLE,
	components: {
		default: () => import('./PayGenView.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true
	}
};

export const PayGenRoute: RouteRecordRaw = {
	path: '/pay-gen',
	name: RouteKeys.PAY_GEN,
	components: {
		default: () => import('./PayGenViewHome.vue'),
		...defaultLayoutComponents
	},
	redirect: PayGenViewRoute,
	children: [PayGenViewRoute, TeamRoute, EstablishmentRoute, EmployeeRoute, TeamRoute, PayGenMultiRoutes],
	meta: {
		requiresAuth: true,
		isForbidden: () => {
			const { isUser } = storeToRefs(useRolesStore());
			return computed(() => !isUser.value);
		},
		label: 'paygen.navigation',
		icon: 'compass-drafting',
		isNavEntry: true,
		order: 4
	}
};
