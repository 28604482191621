import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.locale("fr");
dayjs.extend(customParseFormat);
dayjs("12-25-1995", "MM-DD-YYYY");

export function dateToString(date: Date | undefined): string | undefined {
  if (date === undefined) {
    return undefined;
  }
  const dsDate = dayjs(date);
  if (!dsDate.isValid()) {
    return undefined;
  } else {
    return dayjs(date).format("DD/MM/YYYY HH:mm");
  }
}

export function stringToDate(date: string | undefined): Date | undefined {
  if (date === undefined) {
    return undefined;
  }
  const dsDate = dayjs(date);
  if (!dsDate.isValid()) {
    console.error(date);
    return undefined;
  } else {
    return dsDate.toDate();
  }
}
export const msDayjs = dayjs;
