import { Faker, base, fr } from '@faker-js/faker';

import { randomNumber, sizedNumber } from '~/utils';

import { Company, ContractType, Employee, contractTypes } from './model';


export const faker = new Faker({
	locale: [fr, base]
});

const establishmentNames = [
	'Siege',
	'Usine des Alpes',
	'Annexe de Nantes',
	'Usine de Carhaix',
	'Port du Havre',
	'Port de Brest',
	'Commerce de Nantes',
	'Centre de stockage',
	'Industries',
	'Entreprise',
	'Corporation',
	'Etablissements',
	'Père et fils',
	'Boulon',
	'Ecrou',
	'Music',
	'Dupond',
	'Dupont',
	'Nantais'
];

export function randomDate(start: Date, end: Date) {
	return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export function randomDateYear(startYear: number, endYear: number) {
	const start = new Date();
	start.setFullYear(startYear);
	start.setMonth(0);
	start.setDate(1);

	const end = new Date();
	end.setFullYear(endYear);
	end.setMonth(11);
	end.setDate(30);
	return randomDate(start, end);
}

export function formatInputDate(dateIn: Date | string | undefined) {
	if (dateIn === undefined) {
		return 'UN_ERROR';
	}
	const date = new Date(dateIn);
	return `${sizedNumber(date.getFullYear(), 4)}-${sizedNumber(date.getMonth() + 1, 2)}-${sizedNumber(date.getDate(), 2)}`;
}

export function randomCompany(): Company {
	return new Company();
}

export function randomEstablishmentName() {
	const index = Math.floor(Math.random() * (establishmentNames.length - 1));
	return establishmentNames[index];
}

export function fakerNir(empl: Employee): string {
	if (empl.birthDate === undefined || empl.birthDepartment === undefined) {
		return 'UN_ERROR';
	}
	let nirOut = '';
	if (empl.gender == 'male') {
		nirOut += '1';
	} else {
		nirOut += '2';
	}
	let birthDepartment = empl.birthDepartment.toUpperCase().trim();
	if (birthDepartment == '2A') {
		birthDepartment = '19';
	} else if (birthDepartment == '2B') {
		birthDepartment = '18';
	}
	nirOut += ' ';
	nirOut += empl.birthDate?.getFullYear().toString().substring(2, 4);
	nirOut += ' ';
	nirOut += sizedNumber(empl.birthDate?.getMonth() + 1, 2);
	nirOut += ' ';
	nirOut += birthDepartment;
	nirOut += ' ';
	nirOut += empl.birthCityCode?.substring(2, 5);
	nirOut += ' ';
	nirOut += sizedNumber(Math.floor(Math.random() * 895) + 1, 3);
	nirOut += ' ';
	const numForKey: number = +nirOut.replaceAll(' ', '').replaceAll('2A', '19').replaceAll('2B', '18');
	nirOut += sizedNumber(97 - (numForKey % 97), 2);
	return nirOut;
}

export function gender(): 'female' | 'male' {
	if (Math.random() > 0.5) {
		return 'male';
	} else {
		return 'female';
	}
}

export function randomContractType(): ContractType {
	const jobIndex = Math.floor(Math.random() * (contractTypes.length - 1));
	return contractTypes[jobIndex];
}

export function fakeSiren(): string {
	return `${randomNumber(1, '56')}${randomNumber(8)}`;
}
