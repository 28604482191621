<template>
	<v-icon v-bind="$attrs" :icon="value" :size="size" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { VIcon } from 'vuetify/components';
import { $fa, vuetifyIconAliases } from '~/plugins';

const props = withDefaults(
	defineProps<{
		icon: string | `$${string}`;
		size?: string | number;
	}>(),
	{
		size: 'small'
	}
);

const value = computed(
	() => (props.icon?.startsWith('$') ? vuetifyIconAliases?.[props.icon.substring(1)] : $fa(props.icon)) ?? $fa('alien-monster')
);
</script>
