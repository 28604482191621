import { watchImmediate } from '@vueuse/core';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useTheme as useVuetifyTheme } from 'vuetify';

import { useObservable, useTheme } from '@silae/composables';
import { AUTH_API, useBackendHttpService } from '~/api';
import { useLoadingService, useStorageService } from '~/services';
import { Clearable } from '~/stores';
import { useAuthenticationStore } from '~/stores/authentication.store';
import { useBackendAppStore } from '~/stores/backend-app.store';
import { parseEnvVariable } from '~/utils';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { SignInRoute } from '~/pages/sign-in/sign-in.route';
import { useToasts } from '~/composables';

export function initBackend(): void {
	const { setBackendURL, setWithCredentials } = useBackendHttpService();

	setBackendURL(import.meta.env.QA2_BACKEND_URL);
	setWithCredentials(true);
}

const autoRefresh = parseEnvVariable(import.meta.env.MS_AUTH_REFRESH);

export function initSessionLostRedirection(): void {
	const { error: showErrorToast } = useToasts();
	const { t } = useI18n();
	const { push, currentRoute } = useRouter();
	const { responseInterceptors, requestInterceptors } = useBackendHttpService();
	const { refreshAuthUsingHeadlessB2C$ } = useAuthenticationStore();
	const { isAuthenticated, accessTokenExpirationTime } = storeToRefs(useAuthenticationStore());

	requestInterceptors.use(async (config: AxiosRequestConfig) => {
		const accessTokenExpired = accessTokenExpirationTime.value < DateTime.now().toMillis();
		const isAuthenticationCall = config.url?.includes(AUTH_API);

		if (isAuthenticated.value && autoRefresh && accessTokenExpired && config.withCredentials && !isAuthenticationCall) {
			console.log('refreshAuthUsingHeadlessB2C$');
			await lastValueFrom(refreshAuthUsingHeadlessB2C$());
		}

		return Promise.resolve(config);
	});

	responseInterceptors.use(
		response => response,
		(error: AxiosError) => {
			const isPublic = currentRoute.value?.matched.some(match => match.meta?.public);

			if (error.response && error.response.status === 401 && !isPublic && isAuthenticated) {
				showErrorToast({
					title: t('common.feedback.error.title'),
					text: t('common.feedback.session_lost')
				});
				push(SignInRoute);
			}
			return Promise.reject(error);
		}
	);
}

export function initErrorInterceptors(): void {
	const { responseInterceptors } = useBackendHttpService();

	responseInterceptors.use(
		response => response,
		(error: AxiosError<ArrayBuffer>) => {
			if (error.response?.config?.responseType === 'arraybuffer') {
				error.response.config.responseType = 'json';
				error.response.data = decodeArrayBufferIntoJson(error);
			}
			return Promise.reject(error);
		}
	);
}

function decodeArrayBufferIntoJson(error: AxiosError<ArrayBuffer, any>) {
	return JSON.parse(new TextDecoder().decode(error.response.data));
}

export function initLoadingService(): void {
	const { requestInterceptors, responseInterceptors } = useBackendHttpService();
	const { addRunningCall, removeRunningCall } = useLoadingService();

	requestInterceptors.use(request => {
		addRunningCall();
		return request;
	});

	responseInterceptors.use(
		response => {
			removeRunningCall();
			return response;
		},
		(error: Error) => {
			removeRunningCall();
			return Promise.reject(error);
		}
	);
}

/***
 set storage ID on sign in
 */
export function initStorageService(): void {
	const { isAuthenticated, principal } = storeToRefs(useAuthenticationStore());
	const storageService = useStorageService();
	watchImmediate(isAuthenticated, isAuthenticated => {
		if (isAuthenticated) {
			storageService.setId(principal.value?.login);
		}
	});
}

// const EMPLOYEE_SELECTION_KEY = "employee-company-selection";
// const MANAGER_SELECTION_KEY = "manager-company-selection";
// const ADMIN_SELECTION_KEY = "admin-company-selection";
// const ACTIVE_ROLE_KEY = "active-role";

// export function initLocalStorageSynchronization(): void {
// 	// selection
// 	const selectionStore = useCompanySelectionStore();
// 	const { employeeCompanyId, managerCompaniesIds, adminCompanyId } = storeToRefs(selectionStore);
// 	useLocaleStorageSynchronization(employeeCompanyId, EMPLOYEE_SELECTION_KEY, selectionStore.selectEmployeeCompany);
// 	useLocaleStorageSynchronization(managerCompaniesIds, MANAGER_SELECTION_KEY, selectionStore.selectManagerCompanies);
// 	useLocaleStorageSynchronization(adminCompanyId, ADMIN_SELECTION_KEY, selectionStore.selectAdminCompany);
//
// 	// role
// 	const rolesStore = useRolesStore();
// 	const { activeRole } = storeToRefs(rolesStore);
// 	useLocaleStorageSynchronization(activeRole, ACTIVE_ROLE_KEY, rolesStore.setActiveRole);
// }

/***
 Check whether backend is up or not
 */
export function useHealthCheck(): void {
	const { trigger } = useObservable();
	trigger(useBackendAppStore().fetchCheckHealth$());
}

/***
 Ensure Vuetify theme is sync with app theme
 */
export function initThemeSync(): void {
	const { theme } = useTheme();
	const vuetifyTheme = useVuetifyTheme();
	watchImmediate(theme, theme => (vuetifyTheme.global.name.value = theme));
}

/***
 clear all necessary stores on sign out
 */
export function clearStateOnSignOut(): void {
	const authenticationStore = useAuthenticationStore();
	const { isAuthenticated } = storeToRefs(authenticationStore);
	const clearables: Clearable[] = [authenticationStore, useStorageService()];

	watch(isAuthenticated, (isAuthenticated, wasAuthenticated) => {
		if (wasAuthenticated && !isAuthenticated) {
			clearables.forEach(store => store.clear());
		}
	});
}

/***
 Redirect to home page when user updates its role or company selection and finds herself on a forbidden route
 */
// export function useForbiddenRouteRedirect() {
// 	const { isForbidden } = useGuardedRoutes();
// 	const { activeRole } = storeToRefs(useRolesStore());
// 	const { employeeCompanyId, adminCompanyId } = storeToRefs(useCompanySelectionStore());
// 	const { push, currentRoute } = useRouter();
//
// 	watchImmediate([employeeCompanyId, adminCompanyId, activeRole], ([newCompanyId, newAdminCompanyId, newRole]) => {
// 		if ((newCompanyId || newAdminCompanyId || newRole) && isForbidden(currentRoute.value)) push(HomeRoute);
// 	});
// }
